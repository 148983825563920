.modal-header {
  border-bottom: 0;
}

.modal-title {
  font-size: 2rem;
  font-weight: bold;
  display: block;
  width: 100%;
}
