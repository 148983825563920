
                @import "src/styles/brands/southeastern/_settings";
              
.page-not-found {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    
    div {
        padding: 15px 20px;
        font-size: 20px;

        &:first-child {
            border-right: 1px solid black;
            font-size: 32px;
        }
    }
}