.sr-only {
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  border: 0 !important;
  color: #757575 !important;
  background-color: #fff !important;;
  font-size: 0 !important;;
}

.btn-primary {
  &:focus,
  &:active,
  &:hover {
    background-color: $brand-secondary !important;
    border-color: $brand-secondary !important;
  }
}
  
.btn-outline-primary {
  &:focus,
  &:active,
  &:hover {
    background-color: $brand-secondary !important;
    border-color: $brand-secondary !important;
  }
}
