a {
  color: $brand-primary;
}

.proof-of-age {
  &__select-button {
      &--renewed {
          .proof-of-age__select-button__name__number {
              color: $brand-primary;
          }
      }
  }
}

.form-text {
  color: #cf492e;
}

.btn-link {
  color: $brand-secondary;
}

.btn-link:hover {
  color: $brand-primary;
}

a:hover {
  color: $brand-primary;
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: #fff;
  background-color: $brand-secondary;
  border-color: $brand-secondary;
}

.btn-primary {
  background-color: $brand-primary;
  border-color: $brand-primary;

  &:focus,
  &:hover {
    background-color: $brand-secondary;
    border-color: $brand-secondary;
  }
}

.btn-outline-primary {
  border-color: $brand-primary;
  color: $brand-primary;

  &:focus,
  &:hover {
    background-color: $brand-secondary;
    border-color: $brand-secondary;
  }
}

.app-header-bar {
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.29);
    display: flex;
    padding: 20px;
    flex-direction: row;
    position: fixed;
    width: 100%;
    z-index: 2;
  
    &__content {
      margin: 0 auto;
      max-width: 773px;
      width: 100%;
    }
  
    &__logo {
      max-width: 100%;
      width: 330px;
    }
  
    &__user-info {
      text-align: right;
    }
  
    &__login-register-buttons {
      align-items: flex-end;
      display: flex;
      justify-content: flex-end;
  
      button {
        margin-left: 10px;
      }
    }

    @media only screen and (max-width: 600px) {
      .dropdown button {
        margin: 0;
        padding: 0;
      }
    }

    .desktop-login-buttons {
      @media only screen and (max-width: 600px) {
        display: none;
      }
    }

    .mobile-login-buttons {
      @media only screen and (min-width: 600px) {
        display: none;
      }

      .dropdown button {
        float: right;
        margin: 0;
        padding: 0;
        position: relative;
        top: -5px;
      }
    }
  }
  
  .main-content {
    padding: 100px 20px 100px 20px;
    max-width: 840px;
    margin: 0 auto;
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 600px) {
      padding-bottom: 30px;
    }
  }
  
  .site-footer {
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.29);
    bottom: 0;
    padding: 20px;
    position: fixed;
    width: 100%;

    @media only screen and (max-width: 600px) {
      display: none;
    }

    a {
      display: block;
      padding: 0 15px;
      text-decoration: none;

      &.back-to-toc-site {
        display: inline-block;
      }
    }

    p {
      margin-bottom: 0;
    }

    &__links {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    .payment-methods-list {
      @media only screen and (max-width: 600px) {
        display: none;
      }

      display: inline-block;
      list-style-type: none;
      margin: 0;

      &__item {
        height: 23px;
        display: inline-block;
        background-repeat: no-repeat;
        background-image: url('/images/payment-card-sprite.png');
        margin-right: 5px;
        width: 32px;

        &--paypal {
          background-size: contain;
          background-image: url(https://www.paypalobjects.com/webstatic/en_US/i/buttons/pp-acceptance-small.png);
        }

        &--visa {
          background-position: 0 -160px;
        }

        &--mastercard {
          background-position: 0 -183px;
        }

        &--maestro {
          background-position: 0 -206px;
        }

        &--amex {
          background-position: 0 -229px;
        }

        &--safekey {
          background-position: 0 -275px;
        }
      }
    }
  }

  .error-well {
    margin-top: 10px;
  
    p:last-child {
      margin-bottom: 0;
    }
  }
  