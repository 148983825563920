$brand-primary: #009ADB;
$brand-secondary: #262078;
$brand-tertiary: #085a91;

.btn-primary {
  &:focus,
  &:active,
  &:hover {
    background-color: $brand-tertiary !important;
    border-color: $brand-tertiary !important;
  }
}
  
.btn-outline-primary {
  &:focus,
  &:hover {
    background-color: $brand-tertiary !important;
    border-color: #085487 !important;
  }
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
    color: #fff;
    background-color: #0D6EFD;
    border-color: #0D6EFD;
}
  