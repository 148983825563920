
                @import "src/styles/brands/southeastern/_settings";
              
.maintenance {
  h1 {
    margin-bottom: 35px;
    text-align: center;
  }

  p {
    text-align: center;
  }
}