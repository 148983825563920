
                @import "src/styles/brands/southeastern/_settings";
              
.download-links {
    h1 {
        text-align: center;
        margin-bottom: 35px;
    }
    
    &__subheading {
        font-size: 20px;
        margin-bottom: 35px;
        text-align: center;
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        img {
            max-width: 140px;
        }
    }

    margin-bottom: 20px;
}

