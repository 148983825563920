
                @import "src/styles/brands/southeastern/_settings";
              
.homepage-faq {
    border-top: 1px solid #B5B5B5;

    display: flex;
    flex-direction: column;
    align-items: center;
    
    scroll-margin-top: 100px;

    h1 {
        margin: 25px 0;
    }
    .accordion {
        max-width: 480px;
        width: 100%;
        
        .accordion-item {
            overflow: hidden;
            border: 1px solid #676A6C;
            border-radius: 5px;
            margin-bottom: 25px;
            .accordion-header {
                
                button {
                    &:after {
                        background-image: var(--bs-accordion-btn-icon);
                    }
                    color: black;
                    border-radius: 0;
                    font-weight: bold;
                    background: white;
                    box-shadow: none;

                    &:focus {
                        background: #B5B5B5;
                    }
                }
            }
        }
    }
}